<template>
  <div>
    <div v-if="loader && !dashboard" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <div v-else-if="user && dashboard">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <div>
          <h1 class="highlighted-color1">
            <span>{{ dashboard.name }}</span>
          </h1>
        </div>
        <div>
          <router-link :to="{ name: 'dashboards.index' }" class="btn btn-primary">{{ $t('back') }}</router-link>
        </div>
      </div>
      <!-- DASHBOARD -->
      <div>
        <!-- EXTERNAL -->
        <template v-if="dashboard.type === 'EXTERNAL'">
          <!-- LOOKER -->
          <div v-if="dashboard.platform === 'LOOKER_STUDIO'">
            <iframe class="dashboard-iframe" :src="dashboard.embed_url" frameborder="0" style="border:0" allowfullscreen></iframe>
          </div> <!-- END LOOKER -->
        </template><!-- END EXTERNAL -->
      </div> <!-- END DASHBOARD -->
    </div>
  </div>
</template>

<script>

import DashboardsService from '@/modules/dashboards/services/dashboards-service'
import { mapState } from 'vuex'

import Loader from '@/components/common/Loader/Loader'

export default {
  components: {
    Loader
  },
  data () {
    return {
      dashboard: null,
      loader: false
    }
  },
  metaInfo () {
    return {
      title: this.dashboard ? this.dashboard.name : ''
    }
  },
  created () {
    this.getDashboard()
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Get dashboard
     */
    async getDashboard () {
      this.loader = true
      try {
        const dashboard = await DashboardsService.getDashboard(this.$route.params.id)
        this.dashboard = dashboard
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .dashboard-iframe {
    width: 100%;
    height: 100vh;
  }
</style>
